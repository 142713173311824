import styled, { css } from 'styled-components';
import { fonts } from '../../styles/typography';
import { dynamicFontSize, hover, media } from '../../styles/mixins';
import { FullWidthContainer } from '../../components/Container/styles';
import { RocketImage } from '../../components/RocketImage/RocketImage';
import { VideoBackground } from '../../components/VideoBackground/VideoBackground';
import { margins } from '../../styles/variables';

export const ArticleHeading = styled.h1`
  ${fonts.NeoSansRegular};
  ${dynamicFontSize(32, 70)};
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  max-width: 800px;
  margin: 0;

  ${media.M`
    margin: initial;
    line-height: 80px;
  `};

  &:after {
    content: '';
    display: block;
    margin: 27px auto 20px;
    height: 2px;
    width: 120px;
    background-color: #fff;
  }

  ${(props) =>
    props.smallMarginTop &&
    css`
      margin-top: 4px;
    `}
  
  ${({ noLineUnder }) =>
    noLineUnder &&
    css`
      &:after {
        display: none;
      }
    `}
    }
`;

export const HeroImageBlockContainer = styled(FullWidthContainer)`
  position: relative;
  overflow: hidden;
  /* transition: height 10000s steps(1); ? */
`;

export const HeroImage = styled(RocketImage)`
  img {
    height: auto !important;
    width: 100% !important;
    position: relative !important;
  }
`;

export const HeroVideo = styled(VideoBackground)`
  height: 100%;
`;

export const HeroImageBlockContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px ${margins.xSmall}px 50px;
  z-index: 3;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: ${(props) => props.theme.color.black};
    opacity: ${(props) => (props.useFilter ? 0.2 : 0)};
    z-index: -2;
    ${(props) =>
      props.backgroundVideo &&
      css`
        z-index: -1;
      `};
  }
`;

export const HeroImageBlockTitle = styled.h2`
  ${fonts.NeoSansRegular};
  ${dynamicFontSize(36, 70)};
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 30px;
  max-width: 750px;
  color: ${(props) => props.theme.color.white};
`;

export const HeroImageBlockLabel = styled.p`
  ${fonts.GoudyOldStyleItalic};
  font-size: 18px;
  margin: 0;
  text-align: center;
  color: ${(props) => props.theme.color.white};
`;

export const HeroImageBlockDescription = styled.div`
  ${fonts.GoudyOldStyleRegular};
  font-size: 20px;
  max-width: 650px;
  line-height: 32px;
  margin: 0px auto 30px;
  color: ${(props) => props.theme.color.white};
  text-align: center;

  ${media.M`
    font-size: 22px;
  `};

  p {
    font-size: inherit;
    margin: 0;
  }
`;

export const HeroImageBlockButtonContainer = styled.div`
  margin-top: 10px;
  bottom: 30px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;

  ${media.HBBP`
    position: static;
    bottom: initial;
    left: initial;
    transform: none;
    right: initial;
  `};

  .swiper-slide & {
    bottom: 50px;
  }
`;

export const HeroImageBlockButton = styled.button`
  ${fonts.NeoSansRegular};
  font-size: 14px;

  text-decoration: none;
  min-width: 160px;
  width: auto;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.125rem;
  color: ${(props) => props.theme.color.white};
  border: 1px solid ${(props) => props.theme.color.white};
  background-color: initial;
  cursor: pointer;
  transition: background-color 0.25s ease 0s, color 0.25s ease 0s,
    border-color 0.25s ease 0s;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${hover`
    color: ${(props) => props.theme.color.black};
    background-color: ${(props) => props.theme.color.white};
  `};

  ${({ theme, isBlack }) =>
    isBlack &&
    css`
      color: ${theme.color.white};
      background: ${theme.color.black};
      border-color: ${theme.color.black};

      &:hover {
        border-color: ${theme.color.white};
      }
    `};

  ${({ theme, isWhite }) =>
    isWhite &&
    css`
      color: ${theme.color.black};
      background: ${theme.color.white};

      &:hover {
        color: ${theme.color.white};
        background: ${theme.color.black};
        border-color: ${theme.color.black};
      }
    `};

  ${({ theme, whiteBorder }) =>
    whiteBorder &&
    css`
      color: ${theme.color.white};
      background: transparent;
      border-color: ${theme.color.white};

      &:hover {
        color: ${theme.color.black};
        background: ${theme.color.white};
        border-color: ${theme.color.white};
      }
    `};

  ${({ theme, blackBorder }) =>
    blackBorder &&
    css`
      color: ${theme.color.black};
      border: 1px solid ${theme.color.black};
    `}

  ${media.HBBP`
    display: inline-flex;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 15px;
    }
  `};

  ${media.IE`
    height: 50px;
  `};
`;
