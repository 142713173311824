import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import useIOSVideoFix from '../../hooks/useIOSVideoFix';

export const VideoBackground = (props) => {
  const { aspectRatio, videoposterimage, videosrc, className, enableClipPath } =
    props;

  const { setVideoRef } = useIOSVideoFix({
    dependencies: [videosrc],
  });

  if (videosrc === 'undefined' || videosrc.length === 0) {
    return null;
  }

  if (aspectRatio) {
    return (
      <AspectRatioContainer aspectRatio={aspectRatio} className={className}>
        <VideoContainer aspectRatio={aspectRatio}>
          <StyledVideo
            ref={setVideoRef}
            poster={videoposterimage}
            enableClipPath={enableClipPath}
            playsInline
            autoPlay
            muted
            loop
          >
            <source src={videosrc} type="video/mp4" />
          </StyledVideo>
        </VideoContainer>
      </AspectRatioContainer>
    );
  }

  return (
    <VideoContainer underContent className={className}>
      <StyledVideo
        ref={setVideoRef}
        poster={videoposterimage}
        enableClipPath={enableClipPath}
        playsInline
        autoPlay
        muted
        loop
      >
        <source src={videosrc} type="video/mp4" />
      </StyledVideo>
    </VideoContainer>
  );
};

const AspectRatioContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: ${(props) => `${props.aspectRatio}%`};
`;

const VideoContainer = styled.div`
  display: flex;

  ${(props) =>
    props.aspectRatio &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `}

  ${(props) =>
    props.underContent &&
    css`
      z-index: -2;
    `}

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const StyledVideo = styled.video`
  ${({ enableClipPath }) =>
    !!enableClipPath &&
    css`
      clip-path: inset(1px 2px);
    `}
`;

VideoBackground.defaultProps = {
  aspectRatio: null,
  videosrc: null,
  videoposterimage: null,
  className: null,
  enableClipPath: false,
};

VideoBackground.propTypes = {
  aspectRatio: PropTypes.number,
  videosrc: PropTypes.string,
  videoposterimage: PropTypes.string,
  className: PropTypes.string,
  enableClipPath: PropTypes.bool,
};
