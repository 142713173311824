import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash/fp';
import { connect } from '../../store/store';
import { Conditional } from '../../components/Conditional/Conditional';
import { LocalizedLink } from '../../components/LocalizedLink/LocalizedLink';
import { VideoBackground } from '../../components/VideoBackground/VideoBackground';
import {
  HeroImageBlockContainer,
  ArticleHeading,
  HeroImageBlockButton,
  HeroImageBlockButtonContainer,
  HeroImage,
  HeroImageBlockContent,
  HeroImageBlockDescription,
  HeroImageBlockLabel,
  HeroImageBlockTitle,
  HeroVideo,
} from './styles';
import Video from '../../components/Video/Video';

import { sizes } from '../../styles/variables';

const HeroImageBlockComponent = (props) => {
  const {
    isMobile,
    isFirstBlock,
    hasFirstHeading,
    buttonColor,
    useHeroImageFilter,
    multipleSizeImageContainer,
    displayOnMobile,
    displayOnDesktop,
    enableClipPath,
  } = props;
  const videoUrl = get(['video', 0, 'urlPath'], props);
  const videoCoverImage = get(['video', 0, 'coverImage'], props);
  const backgroundVideo = videoUrl && !isMobile;
  const mobileVideo = get(['mobileVideo'], props);
  const mobileVideoCoverImage = get(['mobileVideo', 'coverImage'], props);
  const showMobileVideo = mobileVideo && isMobile;
  const smallHeroImage = get(['images', 1], props) || get(['images', 0], props);
  const largeHeroImage = get(['images', 0], props);
  const defaultActiveHeroImage = isMobile ? smallHeroImage : largeHeroImage;
  const { imageMobile, imageTablet, imageDesktop, imageDesktopWide } =
    multipleSizeImageContainer;

  const getAppropriateHeroImage = () => {
    if (typeof window !== 'undefined') {
      const windowInnerWidth = window?.innerWidth;
      if (imageMobile && windowInnerWidth < sizes.small) {
        return imageMobile;
      }
      if (
        imageTablet &&
        windowInnerWidth >= sizes.small &&
        windowInnerWidth < sizes.medium + 1
      ) {
        return imageTablet;
      }
      if (
        imageDesktop &&
        windowInnerWidth >= sizes.medium + 1 &&
        windowInnerWidth < sizes.large
      ) {
        return imageDesktop;
      }
      if (imageDesktopWide && windowInnerWidth >= sizes.large) {
        return imageDesktopWide;
      }
    }
    return defaultActiveHeroImage;
  };

  const [activeHeroImage, setActiveHeroImage] = useState(
    defaultActiveHeroImage
  );

  const setAppropriateHeroImage = () => {
    setActiveHeroImage(getAppropriateHeroImage());
  };

  useEffect(() => setAppropriateHeroImage(), [isMobile, props.images]);

  if (!activeHeroImage) {
    return null;
  }

  return (
    <HeroImageBlockContainer
      noMargin
      data-litium-block-id={get('draftId', props)}
      displayOnDesktop={displayOnDesktop}
      displayOnMobile={displayOnMobile}
    >
      <Conditional show={!backgroundVideo && !showMobileVideo}>
        <HeroImage
          src={get('urlPath', activeHeroImage)}
          alt={get('alt', activeHeroImage)}
          loadInstantly={isFirstBlock}
          previewBase64={get('previewBase64', activeHeroImage)}
          relative
        />
      </Conditional>

      <Conditional show={backgroundVideo}>
        <HeroVideo
          videosrc={videoUrl}
          videoposterimage={
            videoCoverImage || get(['images', 0, 'urlPath'], props)
          }
          enableClipPath={enableClipPath}
        />
      </Conditional>

      <Conditional show={showMobileVideo}>
        <Video
          src={mobileVideo?.urlPath}
          cover={mobileVideoCoverImage || get(['images', 1, 'urlPath'], props)}
          playOnViewport
          hideButton
          loop
          muted
          takeOverContainer
          disableControls
          playsInline
          relative
          autoplay
        />
      </Conditional>

      <HeroImageBlockContent
        backgroundVideo={backgroundVideo}
        useFilter={useHeroImageFilter}
      >
        <Conditional show={!get('isArticle', props)}>
          <HeroImageBlockLabel>{get('subHeading', props)}</HeroImageBlockLabel>
        </Conditional>

        {get('isArticle', props) || !get('body', props) ? (
          <Conditional show={!!get('title', props)}>
            <ArticleHeading
              smallMarginTop={!!get('subHeading', props)}
              as={hasFirstHeading ? 'h1' : 'h2'}
              noLineUnder
            >
              {get('title', props)}
            </ArticleHeading>
          </Conditional>
        ) : (
          <HeroImageBlockTitle as={hasFirstHeading ? 'h1' : 'h2'}>
            {get('title', props)}
          </HeroImageBlockTitle>
        )}

        <Conditional
          show={
            !!get('body', props) &&
            (!isMobile || get('displayBodyOnMobile', props))
          }
        >
          <HeroImageBlockDescription
            dangerouslySetInnerHTML={{ __html: get('body', props) }}
          />
        </Conditional>

        <HeroImageBlockButtonContainer>
          {map((button) => {
            if (
              !get('heading', button) ||
              !get(['link', 'id'], button) ||
              !get(['link', 'type'], button)
            )
              return false;

            return (
              <LocalizedLink
                key={get(['link', 'id'], button)}
                as={get(['link', 'id'], button)}
                data={{ id: get(['link', 'id'], button) }}
                page={get(['link', 'type'], button)}
              >
                <HeroImageBlockButton
                  isBlack={buttonColor === 'whiteOnBlack'}
                  isWhite={buttonColor === 'blackOnWhite'}
                  whiteBorder={buttonColor === 'whiteBorder'}
                  blackBorder={buttonColor === 'blackBorder'}
                >
                  {get('heading', button)}
                </HeroImageBlockButton>
              </LocalizedLink>
            );
          }, get('buttonCollection', props))}
        </HeroImageBlockButtonContainer>
      </HeroImageBlockContent>
    </HeroImageBlockContainer>
  );
};

HeroImageBlockComponent.defaultProps = {
  isMobile: false,
  isFirstBlock: false,
  hasFirstHeading: false,
  buttonColor: null,
  useHeroImageFilter: true,
  multipleSizeImageContainer: {},
  displayOnMobile: true,
  displayOnDesktop: true,
  enableClipPath: false,
};

HeroImageBlockComponent.propTypes = {
  isMobile: PropTypes.bool,
  isFirstBlock: PropTypes.bool,
  hasFirstHeading: PropTypes.bool,
  buttonColor: PropTypes.string,
  useHeroImageFilter: PropTypes.bool,
  multipleSizeImageContainer: PropTypes.object,
  displayOnMobile: PropTypes.bool,
  displayOnDesktop: PropTypes.bool,
  enableClipPath: PropTypes.bool,
};

export const HeroImageBlock = connect(HeroImageBlockComponent, {}, (store) => ({
  isMobile: get('viewState.isMobile', store),
}));
